@import '../../../../assets/styles/variables';

.smr-card {
  &__title {
    font-family: $fontFamily;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: $colorText;
    margin: 0;
    text-overflow: clip;
  }
}
