.notificationItem {
  background: #f2f8fd;
  border-radius: 20px;
  //height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 14px 16px;
  cursor: pointer;
  .notificationContent {
    width: 353px;
    display: flex;
    flex-direction: column;
    .title {
      font-weight: 500;
      font-size: 16px;
      color: #0a0a0a;
      padding: 0;
      margin: 0;
    }

    .text {
      font-weight: 500;
      font-size: 14px;
      color: #0a0a0a;
      margin-top: 4px;
      margin-right: 10px;
    }

    .link {
      color: #3a57e8;
      font-weight: 500;
      font-size: 12px;
      margin-top: 12px;
    }
  }

  .notificationContext {
    width: 57px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 4px;
    .day {
      color: #757575;
      padding: 0;
      margin: 0;
    }

    .time {
      color: #757575;
    }

    .count {
      width: 24px;
      height: 24px;
      background: #eb5757;
      color: #fff;
      padding: 4px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 13px;
      margin: 0;
    }
  }
}
