.f-user-info {
  &__main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    row-gap: 16px;
  }
  &__main-address {
    margin-top: 16px;
  }
  &__main-bottom {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
    row-gap: 16px;
  }
  &__main-phone {
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    div + label {
      padding-top: 25px;
    }
  }

  &__main-btn {
    margin-top: 16px;
    margin-right: 8px;
    padding: 8px 16px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__footer {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
}
