.b-sidebar-menu {
  &__child {
  }

  &__item {
    padding: 6px 0px 6px 6px;

    &--parent {
      padding-left: 4px;
    }

    &--single {
      padding: 0px;
    }
  }

  &__arrow {
    margin-left: auto;
    margin-right: 4px;
  }

  &__item-label {
    margin-left: 8px;
  }
  &__link {
    &-item {
      color: #757575;
      display: flex;
      align-items: center;
      div {
        margin-left: 16px;
        svg {
          margin-right: 8px;
          circle {
            stroke: #424242;
            path {
              stroke: #424242;
            }
          }
          path {
            stroke: #424242;
          }
        }
      }
    }
    &-arrow {
      width: 20px;
      height: 20px;

      svg {
        path {
          stroke: #3a57e8 !important;
        }
      }
    }

    &--active {
      background-color: #3a57e8;
      div {
        color: #fff;
        div {
          color: #fff;

          span {
            svg {
              circle {
                stroke: #fff;
                path {
                  stroke: #fff;
                }
              }
              path {
                stroke: #fff;
              }
            }
          }
          svg {
            circle {
              stroke: #fff;
              path {
                stroke: #fff;
              }
            }
            path {
              stroke: #fff;
            }
          }
        }
      }
      span {
        svg {
          // border: 1px solid red;
          path {
            stroke: #fff !important;
          }
        }
      }
    }

    a {
      color: #757575;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        background-color: #3a57e8;
        color: #fff;
        span {
          svg {
            // border: 1px solid red;
            path {
              stroke: #fff !important;
            }
          }
        }
        div {
          color: #fff;

          span {
            svg {
              circle {
                stroke: #fff;
                path {
                  stroke: #fff;
                }
              }
              path {
                stroke: #fff;
              }
            }
          }
          svg {
            circle {
              stroke: #fff;
              path {
                stroke: #fff;
              }
            }
            path {
              stroke: #fff;
            }
          }
        }
      }
    }
  }
}
