.container {
  position: fixed;
  border-radius: 8px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000000, 0.7);
  z-index: 1111;
  .loader {
    div {
      border: 5px solid white;
      border-bottom-color: #3a57e8;
    }
  }
  .sended {
    max-width: 298px;
    border-radius: 8px;
    background-color: white;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .sendText {
      margin-top: 12px;
      font-weight: 500;
      font-size: 24px;
      color: #000;
    }

    .sendButton {
      margin-top: 24px;
    }
  }

  .content {
    width: 80vw;
    max-width: 900px;
    border-radius: 8px;
    background-color: #f5f5f5;
    // padding: 16px;
    display: flex;
    flex-direction: column;

    .head {
      display: flex;
      padding: 16px;
      margin-bottom: 12px;
      border-radius: 8px 8px 0 0;
      justify-content: space-between;
      background-color: white;
      align-items: center;
      .title {
        color: #0a0a0a;
        font-size: 32px;
        font-weight: 500;
      }
      .close {
        border: none;
        background: transparent;
        outline: none;
        cursor: pointer;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          padding: 0;
          margin: 0;
          width: 32px;
          height: 32px;
          path {
            stroke: #000000;
          }
        }
      }
    }

    .title {
      font-family: 'Roboto', sans-serif;
      font-size: 24px;
      font-weight: 500;
    }

    .filter {
      background-color: white;
      box-sizing: border-box;
      padding-top: 16px;
      padding-bottom: 8px;
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 16px;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
      color: #000;
      margin-top: 24px;
    }

    .input {
      margin-top: 16px;
      .buttonContainer {
        display: flex;
        justify-content: flex-end;
        .button {
          margin: 0 16px 32px 0;
        }
      }
    }
  }
}

.form {
  margin: 4px 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: white;

  .roles {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  .checkboxWrapper {
    margin-bottom: 16px;
  }
}
