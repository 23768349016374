@import '../../assets/styles/variables';

.section-author {
  &__panel {
    background: $colorWhite;
    border-radius: 8px;
    padding: 24px;
    box-sizing: border-box;
  }

  &__grid {
    display: flex;
  }

  &__thumb {
    width: 92px;
    height: 92px;
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 32px;
    background: $colorGrayLight;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__info {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 12px;

    a + a {
      margin-left: 8px;
    }
  }

  &__footer-whatsapp {
    width: 34px;
    height: 40px;
    background-color: #3a57e8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-left: 10px;
    svg {
      rect {
        stroke: #fff;
      }
      path {
        stroke: #fff;
      }
    }
  }
}
