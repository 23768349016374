@import '../../assets/styles/variables';

.b-nav {
  &__list {
    list-style: none;
    padding: 0;
    margin: 0 0 0 -16px;
    display: flex;
    align-items: center;
  }

  &__item {
    margin-left: 16px;
  }

  &__link {
    text-decoration: none;
  }

  &__text {
    font-family: $fontFamily;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $colorText;
  }
}
