@import '../../../../assets/styles/variables';

.b-attachment-card {
  box-shadow: 0 0.625rem 1.875rem rgb(0 0 0 / 3%);
  border-radius: 8px;
  cursor: pointer;
  margin-right: 20px;
  padding: 16px;
  max-width: 288px;
  min-width: 258px;
  background-color: #fff;
  margin-bottom: 20px;

  &__file {
    padding: 1rem !important;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__info {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  &__date {
    color: #8a92a6;
    font-size: 12px;
  }

  &__size {
    font-weight: 400;
    color: $colorBlue;
    margin-left: auto;
    font-size: 12px;
  }

  &__name {
    display: flex;
    align-items: center;

    svg {
      color: $colorBlue;
    }

    p {
      font-size: 14px;
      margin-left: 4px;
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__description {
    margin-top: 10px;
    color: #8a92a6;
    font-size: 12px;

    &:hover {
      color: $colorBlue;
    }
  }
}
