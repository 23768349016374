.container {
  width: 1512px;
  margin: 0 auto;
  .title {
    font-weight: 900;
    font-size: 48px;
    text-align: center;
    margin: 48px 0 0 0;
    padding: 0;
  }
  .main {
    // width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    margin-top: 64px;
  }
}
