@import '../../../../../../assets/styles/variables';
.notification {
  background: #ffff;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  .content {
    .title {
      font-size: 20px;
      font-weight: 500;
    }

    .subTitle {
      font-weight: 400;
      font-size: 16px;
      color: #000;
    }

    .link {
      color: #3a57e8;
      cursor: pointer;
      font-weight: 500;
      font: 16px;

      &.disabled {
        color: $colorGray;
        cursor: default;
      }
    }
  }
  .date {
    width: 121px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    .dateContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      .day,
      .time {
        margin: 0;
        padding: 0;
        color: #757575;
        font-weight: 400;
        font-size: 14px;
      }
      .time {
        margin-top: 4px;
      }
    }
  }
}
