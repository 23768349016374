.b-parameter-value {
  display: flex;

  + .b-parameter-value {
    margin-top: 20px;
  }

  &__title {
    color: #232d42;
    width: 40%;
  }

  &__text {
    color: #8a92a6;
    flex: 1;
  }
}
