.b-avatar {
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__input {
    display: none;
  }
}
