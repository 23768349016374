@import '../../assets/styles/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    div {
      border: 5px solid white;
      border-bottom-color: #3a57e8;
    }
  }

  .content {
    padding: 20px;
    background-color: $colorWhite;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text {
      font-weight: 500;
      font-size: 24px;
      color: #000;
      margin-top: 24px;
      text-align: center;
    }
    .subText {
      font-weight: 400;
      font-size: 16px;
      color: #000;
      margin-top: 24px;
      text-align: center;
    }

    .buttonContainer {
      display: flex;
      justify-content: center;
      margin-top: 24px;

      .button {
        min-width: 50px;
      }

      .buttonCancel {
        background-color: $colorCyan;
        border: $colorCyan;
      }
      > button + button {
        margin-left: 8px;
      }
    }
  }
}
