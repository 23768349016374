.b-notifications {
  position: fixed;
  bottom: 0;
  right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 8px 0;
  z-index: 9999;

  > div {
    width: 450px;
    margin-bottom: 20px;
  }
}
