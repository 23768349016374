.b-notification {
  position: relative;
  display: flex;
  box-sizing: border-box;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #4f8bff;

  &--error {
    background-color: #da616c;
  }

  &--success {
    background-color: #5bcd71;
  }

  &__icon-container {
    flex: 0;
    margin-right: 16px;
    color: #fff;
  }

  &__message {
    flex: 1;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin: 0;
  }
}
