.companies {
  background-color: #ffffff;
  border-radius: 10px;
  .inputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    .textField {
    }
    .selectContainer {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
      margin: 16px 0 16px 0;
    }
  }
}
