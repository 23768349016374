.empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;

  &__title {
    font-family: Roboto;
    font-size: 16px;
    text-align: center;
    margin: 24px 0 0;
  }
}
