@import '../src/assets/styles';

*,
body,
*::before,
*::after {
  box-sizing: border-box;
}

.page-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  color: #000000;
  margin: 0;
}

img,
svg {
  vertical-align: middle;
}

a {
  color: $colorBlue;
  text-decoration: none;
}

h5,
.h5 {
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #232d42;
  padding-bottom: 1rem !important;
}
