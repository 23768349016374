@import '../../assets/styles/variables';

.smr-select {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--error {
    .smr-select__helper-text {
      color: $colorRed;
    }

    .smr-select__select {
      width: 100%;
      border-color: $colorRed;
    }
  }

  &__label {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $colorGray;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 48px;
    border: 1px solid $colorGrayLight;
    border-radius: 8px;
    padding: 0 16px;
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    outline: none;
    color: $colorText;
    box-sizing: border-box;
    background-color: $colorGrayLight;
    background-image: url('./chevron.svg');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 12px);
    background-position-y: 50%;

    > option[value=''][disabled] {
      color: $colorGray;
    }
  }

  &__helper-text {
    font-size: 12px;
    margin: 8px 8px 0 8px;
  }
}
