@import '../../assets/styles/variables';

.template-search {
  &--paper {
    background-color: #fff;
    margin-top: 16px;
    border-radius: 10px;
    padding: 24px;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }

  &__empty {
    margin-top: 12px;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    padding: 16px 0;
    box-sizing: border-box;

    svg {
      path {
        stroke: $colorBlue;
      }
    }
    + * {
      margin-top: -16px !important;
    }

    @media (max-width: 640px) {
      display: none;
    }
  }

  &__control {
    padding: 6px;
    border: none;
    background: $colorWhite;
    color: $colorBlue;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    margin-left: 8px;
    margin-bottom: 12px;
    &--active {
      background-color: $colorBlue;
      color: $colorWhite;
      svg {
        path {
          stroke: $colorWhite;
        }
      }
    }
  }

  &__grid {
    &--inline {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 980px) {
  .template-search__controls {
    display: none;
  }
}
