@import '../../assets/styles/variables';

.b-header {
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  padding: 8px 0;
  height: 64px;
  position: relative;
  z-index: 5;

  &__container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;

    &--wide {
      max-width: 100% !important;
    }
  }

  &__left {
    margin: auto 0;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
    }
  }

  &__left-btn {
    border: 1px solid #ededed !important;

    span {
      color: black;
      svg {
        path {
          stroke: black;
        }
      }
    }
  }

  &__right {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    justify-content: flex-end;
  }

  &__logo {
    flex-shrink: 0;
    display: flex;
    width: 250px;
    align-items: center;

    &.logo .logo {
      &__image {
        height: 40px;
      }

      &__content {
        display: flex;
        flex-direction: column;
        margin-left: 8px;

        .b-header__text {
          margin: 0;
        }
      }
    }
  }

  &__text {
    font-family: $fontFamily;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $colorText;

    &--small {
      font-family: $fontFamily;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $colorGray;
    }
  }

  &__search {
  }

  &__controls {
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: 640px) {
      display: none;
    }
  }

  &__profile {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    padding: 0;
    outline: none;
    margin: 0 16px 0 16px;
    cursor: pointer;
    &-card {
      position: absolute;
      bottom: -10px;
      right: 290px;
    }
    img,
    .img {
      height: 48px;
      width: 48px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 8px;
    }

    > div {
      text-align: left;

      > p {
        margin: 0;

        &:first-child {
          font-family: $fontFamily;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.1px;
          color: #424242;
        }

        &:last-child {
          font-family: $fontFamily;
          font-weight: 400;
          font-size: 10px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: $colorGray;
        }
      }
    }
  }

  &__action {
    font-family: $fontFamily;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $colorBlue;
    border: none;
    padding: 0;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    margin-left: 16px;

    &:hover {
      text-decoration: underline;
    }
  }

  .b-nav {
    margin-left: 16px;
  }
}
