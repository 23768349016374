.container,
.containerReverse {
  display: inline-flex;
  align-items: center;
  // justify-content: space-between;
  flex-direction: row-reverse;
  .rating {
    color: #424242;
    font-size: 14px;
    font-weight: 400;
    line-height: 0;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    // margin-top: 4px;
  }
  .starList {
    margin: 0;
    margin-left: 0;
    padding: 0;
    height: 14px;
    list-style: none;
    display: flex;
    align-items: center;

    .star {
      display: block;
      line-height: 0;
      &:not(:first-child) {
        margin-left: 5px;
      }

      svg {
        path {
          stroke: #3a57e8;
        }
      }
    }

    .active {
      display: block;
      line-height: 0;
      &:not(:first-child) {
        margin-left: 5px;
      }
      svg {
        path {
          stroke: #3a57e8;
        }
        fill: #3a57e8;
      }
    }
  }
}

.containerReverse {
  flex-direction: row;
}
