.notificationContainer {
  width: 494px;
  height: 528px;
  background: #ffffff;
  position: absolute;
  z-index: 1111;
  top: 84px;
  right: 8px;
  border-radius: 24px;

  box-shadow: 2px 1px 7px 0px rgb(0 0 0 / 32%);
  padding: 16px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .title {
      color: #0a0a0a;
      font-weight: 500;
      font-size: 24px;
    }

    .close {
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      border: 1px solid #d9dce3;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffff;
    }
  }

  .main {
    overflow: auto;
    height: calc(100% - 50px);
    padding: 0 12px 0 0;
    .notificationLists {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      background: #d9dce3;
    }
    &::-webkit-scrollbar-thumb {
      background: #3a57e8;
    }
  }
}
