@import '../../assets/styles/variables';

.smr-checkbox {
  display: flex;
  min-height: 24px;
  align-items: center;
  + .smr-checkbox {
    margin-top: 12px;
  }

  &--oked {
    cursor: pointer;
    &::after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background: $colorBlue;
      position: absolute;
      left: 36%;
      top: 50%;

      transform: translate(-50%, -50%);
    }
    position: relative;

    .smr-checkbox__input:checked + .smr-checkbox__placeholder {
      background: #fff;
      z-index: 1111;
      &::before,
      &::after {
        display: block;
        z-index: 1111;
      }

      &::before {
        content: '';
        width: 12px;
        height: 2px;
        background: #ffff;
        left: 4px;
        top: 7px;
        z-index: 1111;
        transform: rotate(-45deg);
      }

      &::after {
        content: '';
        width: 5px;
        height: 2px;
        background: #ffff;
        transform: rotate(45deg);
        top: 9px;
        z-index: 1111;
        left: 2px;
      }
    }
  }

  &--radio {
    &.smr-checkbox--disabled {
      .smr-checkbox__placeholder {
        border-color: $colorGrayLight;
      }

      .smr-checkbox__label {
        color: $colorGrayLight;
      }
    }

    .smr-checkbox__placeholder {
      border-radius: 50%;

      &::after {
        display: none;
      }

      &::before {
        width: 10px;
        height: 10px;
        background-color: $colorBlue;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        margin-left: -5px;
        margin-top: -5px;
      }
    }

    .smr-checkbox__input:checked + .smr-checkbox__placeholder {
      &::before {
        display: block;
      }
    }
  }

  &--checkbox {
    .smr-checkbox__input:checked + .smr-checkbox__placeholder {
      background: $colorBlue;

      &::before,
      &::after {
        display: block;
      }

      &::before {
        content: '';
        width: 12px;
        height: 2px;
        background: #fff;
        left: 4px;
        top: 7px;
        transform: rotate(-45deg);
      }

      &::after {
        content: '';
        width: 5px;
        height: 2px;
        background: #fff;
        transform: rotate(45deg);
        top: 9px;
        left: 2px;
      }
    }
  }

  &__input {
    display: none;
  }

  &__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: $colorText;
    font-family: $fontFamily;
    margin: 0 0 0 8px;
    cursor: pointer;
  }

  &__placeholder {
    border-radius: 5px;
    width: 20px;
    height: 20px;
    border: 2px solid $colorBlue;
    position: relative;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: none;
    }
  }
}
