.m-kato {
  z-index: 100002 !important;

  &__header {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__close-btn {
    cursor: pointer;
    svg {
      width: 35px;
      height: 35px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
