@import '../../assets/styles/variables';

.smr-text-field {
  display: flex;
  flex-direction: column;
  position: relative;

  &--not-editable {
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(255, 255, 255, 0.7);
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  &--error {
    .smr-text-field__helper-text {
      color: $colorRed;
    }

    .smr-text-field__input-container {
      border-color: $colorRed;
    }
  }

  &__eye {
    path {
      stroke: $colorBlue !important;
    }
  }

  &__clear {
    margin-top: 8px;
    align-self: flex-start;
  }

  &__label {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $colorGray;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__input {
    height: 48px;
    padding: 0 12px;
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    outline: none;
    color: $colorText;
    box-sizing: border-box;
    border: none;
    background-color: transparent;
    &--multiline {
      height: 200px;
      display: block;
      resize: none;
      width: 100%;
      padding: 0 12px;
      padding-top: 10px;
    }

    &::placeholder {
      color: $colorGray;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $colorGray;
    }

    &::-ms-input-placeholder {
      color: $colorGray;
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &__helper-text {
    font-family: $fontFamily;
    font-size: 12px;
    margin: 8px 8px 0 8px;
  }
}
