@import '../../assets/styles/variables';

@mixin buttonColorWithVariant($name, $color, $hoverColor, $textColor) {
  &--#{$name} {
    &.smr-button--contained {
      background-color: $color;
      border-color: $color;
      color: $textColor;

      &:hover {
        background-color: $hoverColor;
        border-color: $hoverColor;
      }

      .kdss-loader__item {
        border-color: $textColor transparent transparent transparent;
      }
    }

    &.smr-button--outlined {
      background-color: transparent;
      border-color: $color;
      color: $color;

      &:hover {
        border-color: $hoverColor;
        color: $hoverColor;
      }

      .kdss-loader__item {
        border-color: $color transparent transparent transparent;
      }
    }

    &.smr-button--text {
      background-color: transparent;
      border-color: transparent;
      color: $color;

      &:hover {
        border-color: transparent;
        color: $hoverColor;
      }

      .kdss-loader__item {
        border-color: $color transparent transparent transparent;
      }
    }
  }
}

.smr-button {
  outline: none;
  text-align: center;
  padding: 0 8px;
  margin: 0;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  position: relative;

  @include buttonColorWithVariant(
    default,
    $colorWhite,
    $colorGrayLight,
    #424242
  );

  @include buttonColorWithVariant(
    primary,
    $colorBlue,
    rgba($colorBlue, 0.9),
    $colorWhite
  );

  @include buttonColorWithVariant(
    danger,
    $colorRed,
    rgba($colorRed, 0.9),
    $colorWhite
  );

  @include buttonColorWithVariant(
    cyan,
    $colorCyan,
    rgba($colorCyan, 0.9),
    $colorBlue
  );

  &--disabled,
  &--loading {
    background-color: $colorGray !important;
    border-color: $colorGray !important;
    color: #fff !important;
  }

  &--loading {
    .smr-button__text {
      visibility: hidden;
    }

    .smr-button__icon {
      visibility: hidden;
    }
  }

  &__text {
    font-family: $fontFamily;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: inherit;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    + .smr-button__text {
      margin-left: 4px;
    }
  }

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin-left: -12px;
    margin-top: -12px;
  }
}
