.modal-tender-create {
  &__content {
    background-color: #f5f5f5;
    margin: 24px -16px -24px;
    padding: 24px;
    box-sizing: border-box;
    display: grid;
    grid-gap: 16px;
    overflow-y: auto;
    max-height: 700px;
  }

  .b-stepper {
    margin: -16px;
  }
}
