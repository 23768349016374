.b-panel {
  border: 1px solid #fff;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: #fff;

  &--transparent {
    background-color: transparent;
    border-color: #ebeefd;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.5rem 1.5rem;
    border-bottom: 1px solid #eeeeee;

    + .b-panel__content {
      padding: 1.5rem 1.5rem;
    }
  }

  &__title {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2;
    color: #232d42;
    margin: 0;
  }

  &__content {
  }
}
