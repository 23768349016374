$colorWhite: #fff;
$colorRed: #ba1b1b;
$colorBlue: #3a57e8;
$colorCyan: #c3dff7;
$colorGray: #757575;
$colorGrayLight: #f5f5f5;
$colorText: #0a0a0a;
$colorGreen: #25aa51;

$fontFamily: 'Roboto', sans-serif;

$breakpoint-tablet: 48rem; // Equivalent to 768px
$breakpoint-desktop: 64rem; // Equivalent to 1024px
