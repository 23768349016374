@import '../../assets/styles/variables';

.smr-filter-tabs {
  &__header {
    display: flex;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
  }

  &__item {
    &:not(:first-child) {
      margin-left: 4px;

      @media (max-width: $breakpoint-tablet) {
        margin-left: 0px;
      }
    }

    &--active {
      .smr-filter-tabs__button {
        letter-spacing: -0.1px;
        font-weight: 500;
        background-color: #fff;

        //@media (max-width: 640px) {
        //  background-color: transparent;
        //  color: #3A57E8;
        //  border-bottom: 2px solid #3A57E8;
        //}
      }
    }

    @media (max-width: $breakpoint-tablet) {
      margin-left: 0px;
    }
  }

  &__button {
    font-family: $fontFamily;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    padding: 12px 24px;
    background: #ededed;
    border-radius: 8px 8px 0 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $colorText;
    letter-spacing: 0.25px;
    cursor: pointer;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
    }

    @media (max-width: 640px) {
      background-color: transparent;
      color: #757575;
      border-bottom: 2px solid transparent;
      margin-bottom: 32px;
    }
  }

  &__content {
  }
}
