.b-signer-card {
  padding: 38px 24px;
  border: 1px solid #ebeefd;
  border-radius: 8px;
  margin-right: 30px;
  flex: 1;

  &__company {
  }

  &__sender-type {
    color: #8a92a6;
    margin: 8px 0;
    font-weight: 700;
  }

  &__owner-name {
    color: #8a92a6;
  }

  &__owner-mail {
    color: #8a92a6;
  }

  &__signed {
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 13px;

    p {
      margin-left: 8px;
    }

    &--sign {
      color: #1aa053;
    }

    &--unsign {
      color: #b28e00;
    }
  }
}
