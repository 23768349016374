.users-filter {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0 10px 10px 10px;

  &__grid {
    margin: 0 0 16px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
  }

  &__footer {
    text-align: right;
  }
}
