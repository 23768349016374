.smr-default-layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__content {
    padding: 16px 0;
    box-sizing: border-box;
    flex: 1;
  }
}
