@import '../../assets/styles/variables';

.b-file-picker {
  box-sizing: border-box;
  // padding: 24px;
  // border: 1px dashed #ebeefd;
  // border-radius: 8px;
  margin-top: 25px;
  font-family: $fontFamily;
  position: relative;

  &--active {
    border-color: $colorBlue;
  }

  &__loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($colorWhite, 0.7);
  }

  input {
    display: none;
  }
  &__files {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-direction: row-reverse;
    justify-content: start;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__file {
    background-color: #f5f5f5;
    padding: 8px;
    box-sizing: border-box;
    position: relative;
    flex-wrap: wrap;
    width: 109px;
    height: 118px;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
      border-radius: 12px;
    }

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__filelists {
    display: flex;
    gap: 8px;

    p {
      width: 100%;
      margin: 4px 0 0 0;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.02em;
      color: #232d42;
      word-break: break-all;

      &:first-child {
        width: 30px;
        color: #8a92a6;

        &:after {
          content: '.';
        }
      }
    }

    button {
      font-weight: 400;
      font-size: 12px;
      position: absolute;
      border: none;
      outline: none;
      right: 8px;
      top: 8px;
      background: #0a0a0a;
      cursor: pointer;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // &:hover {
      //   text-decoration: underline;
      // }
    }
  }

  &__footer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-top: 1px solid #eeeeee;
    margin-top: 24px;
    padding-top: 24px;
    box-sizing: border-box;

    p {
      margin: 0 0 0 8px;
      line-height: 24px;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.02em;
      color: #8a92a6;
    }
  }

  // &__trigger {
  //   margin: auto;
  //   font-family: "Roboto", sans-serif;
  //   font-weight: 500;
  //   font-size: 16px;
  //   line-height: 24px;
  //   color: #424242;
  //   display: inline-flex;
  //   align-items: center;
  //   box-sizing: border-box;
  //   padding: 8px 16px;
  //   background: #FFFFFF;
  //   border: 1px solid #EDEDED;
  //   border-radius: 8px;
  //   outline: none;
  //   cursor: pointer;
  // }
  &__trigger {
    width: 109px;
    height: 118px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      width: 69px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      svg {
        width: 24px;
        height: 24px;
        path {
          stroke: #3a57e8;
        }
        circle {
          stroke: #3a57e8;
        }
      }
      span {
        text-align: center;
        color: #3a57e8;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}
