@import '../../assets/styles/variables';

.smr-form {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .smr-form {
    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #0a0a0a;
      font-family: $fontFamily;
      margin: 0 0 24px 0;
    }

    &__content {
      background-color: $colorWhite;
      padding: 24px;
      box-sizing: border-box;
      border-radius: 8px;
    }

    &__submit {
      margin-top: 16px;
      margin-left: auto;
      margin-right: 16px;
    }
  }
}
