.form-tender {
  .form-tender {
    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 16px;
    }

    &__description {
      margin-top: 16px;
    }
  }
  &__clearBtn {
    cursor: pointer;
  }
}
