.m-sign-in {
  display: flex;
  flex-direction: column;
  position: relative;

  > div {
    > div {
      background-color: #fff;
    }
  }

  &__header {
    background-image: url('./bg.jpeg');
    background-size: cover;
    background-position: center;

    > div {
      background-color: rgba(0, 0, 0, 0.65);
      padding: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > p {
        color: #fff;
        margin: 0;
        font-size: 40px;
      }

      > button {
        outline: none;
        border: none;
        padding: 0;
        margin: 0;
        background: transparent;
        cursor: pointer;
      }
    }
  }

  &__heading {
    font-weight: 600;
    font-size: 22px;
    margin: 0 0 24px 0;
    color: #4f4f4f;
    text-align: center;
  }

  &__close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    svg {
      width: 35px;
      height: 35px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #121212;
    margin: 24px 0 0 0;

    > a {
      font-weight: 600;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }
  }

  &__loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
