@import '../../assets/styles/variables';

.p-profile {
  box-sizing: border-box;
  position: relative;
  min-height: calc(100% - 64px);

  &__sidebar {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    box-shadow: 1px 0 8px rgba(0, 0, 0, 0.1);

    ul {
      padding: 0;
      margin: 16px 0 0 0;
      list-style: none;
      background: $colorWhite;

      ul {
        margin: 0;
      }

      li {
        font-size: 14px;

        div {
          font-family: $fontFamily;
          font-weight: 500;
          color: $colorGray;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 40px;

          &.active,
          &:hover {
            background-color: #ebeefc;
          }

          &:hover:not(.active) {
            background-color: #ebeefc;
          }
        }

        a,
        p {
          font-family: $fontFamily;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
          text-decoration: none;
          margin-left: 8px;
          color: inherit;
        }
      }
    }
  }

  &__content {
    margin-left: 250px;
    padding: 16px;
    box-sizing: border-box;
  }

  &__paper {
    &--small {
      padding: 16px;
    }
    + .p-profile__paper {
    }
  }

  &__paper-tabs {
    background-color: #fff;
    border-radius: 8px;
    margin-top: 12px;
    padding: 24px;

    &-professionalData {
      // border: 1px solid red;
      display: flex;
      gap: 16px;
      margin-bottom: 16px;
    }
    &-bottom-btns {
      display: flex;
      margin-top: 12px;
      justify-content: flex-end;
      button {
        &:nth-child(1) {
          border: 1px solid #e0e0e0;
          margin-right: 8px;
          &:hover {
            border: 1px solid #e0e0e0;
            background: #ffff;
          }
        }
      }
    }
  }
  &__paper-tabs-lists {
    list-style: none;
    padding-left: 0;
    line-height: 24px;
    margin-bottom: 0;
    li {
      font-size: 16px;
      font-weight: 400;
      color: #757575;
      display: flex;
      flex-direction: column;
      span {
        color: #0a0a0a;
        font-size: 20px;
        font-weight: 400;
      }
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }

  &__paper-tabs-portfolio {
    &-title {
      font-weight: 400;
      font-size: 20px;
      color: #0a0a0a;
    }
    &-fileLists {
      display: flex;
      gap: 8px;
      div {
        width: 109px;
        height: 118px;
        position: relative;
        cursor: pointer;
        a {
          display: block;
          width: 100%;
          height: 72%;
          bottom: 0;
          position: absolute;
        }
        button {
          position: absolute;
          width: 24px;
          height: 24px;
          right: 8px;
          top: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          background: #0a0a0a;
          border-radius: 100%;
          z-index: 111111111;
        }
        border-radius: 12px;
        img {
          border-radius: 12px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &-file {
      display: flex;
      gap: 8px;
      margin-top: 16px;
      label {
        width: 109px;
        height: 118px;
        display: block;
        background-color: #f5f5f5;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          width: 69px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          svg {
            width: 24px;
            height: 24px;
            path {
              stroke: #3a57e8;
            }
            circle {
              stroke: #3a57e8;
            }
          }
          span {
            text-align: center;
            color: #3a57e8;
            font-size: 13px;
            font-weight: 400;
          }
        }
        input {
          visibility: hidden;
          width: 0;
          height: 0;
        }
      }
    }
    &-reviews {
      background: #f5f5f5;
      border-radius: 10px;
      padding: 16px;
      &:not(:first-child) {
        margin-top: 8px;
      }
      &-info {
        margin-top: 16px;
        display: flex;
        span {
          color: #757575;
          font-weight: 400;
          font-size: 14px;
        }

        b {
          color: #0a0a0a;
          font-weight: 400;
          font-size: 16px;
        }
        img {
          width: 36px;
          height: 36px;
          border-radius: 100%;
          object-fit: cover;
        }
        &-lists {
          display: flex;
          &-right {
            margin-left: 16px;
            display: flex;
          }
        }
      }
      &-user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-info {
          margin-left: 16px;
          display: flex;
          flex-direction: column;
        }
      }

      &-review {
        &--active {
          svg {
            path {
              stroke: #3a57e8;
            }
            fill: #3a57e8;
          }
        }
        color: #757575;
        font-weight: 400;
        font-size: 14px;
        &-rating {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: center;
          span {
            color: #424242;
            font-size: 14px;
            font-weight: 400;
            padding-top: 4px;
          }
          ul {
            margin: 4px;
            margin-left: 0;
            padding: 0;

            list-style: none;
            display: flex;
            align-items: center;
            padding-right: 4px;

            li {
              &:not(:first-child) {
                margin-left: 4px;
              }

              svg {
                path {
                  stroke: #3a57e8;
                }
              }
            }
          }
        }
      }

      &-comments {
        display: flex;
        flex-direction: column;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #757575;
          text-transform: uppercase;
          margin-bottom: 8px;
        }
        &-field {
          display: flex;
          align-items: center;
          width: 100%;
          position: relative;
          input {
            height: 56px;
            border-radius: 10px;
            width: 100%;
            border: none;
            padding: 16px 12px;
            &::placeholder {
              font-size: 16px;
              font-weight: 400;
              color: #0a0a0a;
            }
            &:focus {
              border: 1px solid #3a57e8;
              outline: none;
            }
          }
          span {
            cursor: pointer;
            z-index: 1111111;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      &-options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80px;

        &-comments {
          cursor: pointer;
          svg {
            path {
              stroke: #757575;
            }
          }
        }
        &-comments--active {
          cursor: pointer;
          svg {
            path {
              stroke: #3a57e8;
            }
          }
        }
        &-like {
          cursor: pointer;
          svg {
          }
        }
        &-like--active {
          cursor: pointer;
          svg {
            fill: #3a57e8;
            path {
              stroke: #3a57e8;
            }
          }
        }
      }
      &-lists {
        padding: 0;
        margin: 0;
        margin-top: 8px;
        list-style: none;
      }
    }
  }

  &__paper-tabs-portfolio-bottom {
    background-color: #fff;
    border-radius: 8px;
    margin-top: 12px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-experience {
      font-size: 20px;
      font-weight: 400;
      color: #0a0a0a;
    }
    &-experienceLists {
      background-color: #fff;
      border-radius: 8px;
      margin-top: 12px;
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: start;
      ul {
        margin-top: 4px;
        margin-bottom: 0;
        padding-left: 28px;
      }
    }
    &-descr {
      display: flex;
      flex-direction: column;
    }
    &-title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 4px;
      color: #0a0a0a;
    }
    &-subtitle {
      color: #757575;
      font-size: 16px;
      font-weight: 500;
    }
    &-exprienceLeft {
      width: 174px;
      display: flex;
      align-items: center;
    }
    &-year {
      font-weight: 400;
      font-size: 14px;
      color: #757575;
      display: flex;
      flex-direction: column;
      &-month {
        margin-top: 4px;
      }
    }
    &-pagination {
      background-color: #fff;
      border-radius: 8px;
      height: 56px;
      margin-top: 12px;
      // padding: 24px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &-count {
        display: flex;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 16px;
          color: #757575;
        }
      }

      &-list {
        border-radius: 4px;
        border: 1px solid #ededed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 9px;
        width: 90px;
        height: 40px;
        padding: 8px 12px;
        cursor: pointer;
        svg {
          path {
            stroke: #757575;
          }
        }
      }
      &-lists {
        display: flex;
        align-items: center;
        margin-left: 24px;
        span {
          font-weight: 400;
          font-size: 16px;
          color: #757575;
        }
        &-btns {
          margin-left: 24px;
          display: flex;
          align-items: center;
          width: 74px;
          justify-content: space-between;
          button {
            background: #fff;
            border-radius: 10px;
            width: 34px;
            height: 34px;
            border: 1px solid rgba(#757575, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              background: #75757556;
              svg {
                path {
                  stroke: #fff;
                }
              }
            }
            cursor: pointer;
            svg {
              width: 24px;
              height: 24px;
              path {
                stroke: #757575;
              }
            }
            &:nth-child(1) {
              svg {
                transform: rotate(90deg);
              }
            }
            &:nth-child(2) {
              svg {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
    }
  }

  &__grid {
    width: 1186px;
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // column-gap: 16px;
  }

  &__toolbar {
    background: #ffff;
    display: flex;
    border-radius: 8px;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 16px;

    > p {
      font-weight: 500;
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.5px;
      color: #0a0a0a;
      margin: 0;
    }
  }
  &__user {
    background: #ffff;
    min-height: 664px;
    border-radius: 8px;
    padding: 24px;
    display: flex;

    // justify-content: space-between;
  }
  &__user-userinfo {
    background: #ffff;
    min-height: 664px;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__user-info {
    width: 360px;
  }
  &__user-about {
    margin-top: 16px;
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__user-status {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    position: relative;

    ul {
      position: absolute;
      top: 34px;
      list-style: none;
      padding-left: 0;
      background: #ffffff;
      box-shadow: 2px 1px 7px 0px rgba(0, 0, 0, 0.322);
      width: 98px;
      border-radius: 4px;

      color: #25aa51;
      margin: 0;

      li {
        cursor: pointer;
        padding: 8px 8px;
        // background: #25aa51;
        font-size: 12px;
        font-weight: 500;
        // &:not(:last-child) {
        //   border-bottom: 1px solid #000;
        // }
        // text-align: center;
        &:not(:last-child) {
          // margin-top: 10px;
          border-bottom: 1px solid #ededed;
        }
      }
    }
  }

  &__user-status-arrow--active {
    svg {
      transform: rotate(180deg);
      // border: 1px solid red;
    }
  }
  &__user-status-state {
    background: #25aa51;
    cursor: pointer;
    height: 28px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #ffff;
    padding: 4px 8px;
    justify-content: space-between;
    svg {
      width: 100%;
      height: 100%;
      margin: 0 3px;
    }
  }
  &__user-status-selected {
    background: rgb(187, 33, 33);
    width: 98px;
    cursor: pointer;
    height: 28px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #ffff;
    padding: 4px 8px;
    justify-content: space-between;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__rating {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #424242;
    font-size: 14px;
    font-weight: 400;
    span {
      color: #424242;
      font-size: 14px;
      font-weight: 400;
      padding-top: 4px;
    }
    margin-top: 4px;
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      padding-left: 12.78px;
      margin: 0;
      li {
        &:not(:first-child) {
          margin-left: 4px;
        }
        // cursor: pointer;
        svg {
          path {
            stroke: #3a57e8;
          }
        }
        // &:hover {
        //   svg {
        //     path {
        //       stroke: #3a57e8;
        //     }
        //     fill: #3a57e8;
        //   }
        // }
      }
    }
  }

  &__star--active {
    svg {
      path {
        stroke: #3a57e8;
      }
      fill: #3a57e8;
    }
  }

  &__user-view {
    font-size: 14px;
    padding: 8px 0;
    font-weight: 400;
    color: #424242;
    svg {
      margin-right: 6px;
    }
  }

  &__user-contact {
    padding: 8px 0;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #424242;
    display: flex;
    span {
      margin-left: 8px;
      width: 265px;
    }
  }

  &__user-phone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 24px;
      height: 24px;
    }

    ul {
      line-height: 20px;
      padding: 0;
      margin-left: 6px;
      list-style: none;

      li {
        display: flex;
        align-items: center;

        p {
          color: #424242;
          margin: 0 6px 0 0;
        }

        width: 100%;
        // display: flex;
        // align-items: center;
        font-size: 14px;
        color: #424242;
        font-weight: 400;

        span {
          font-size: 12px;
          color: #9e9e9e;
        }
      }
    }
  }
  &__user-links {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }

  &__user-email {
  }

  &__user-instagram {
  }

  &__user-telegram,
  &__user-website,
  &__user-email,
  &__user-instagram {
    padding: 8px 0;
    font-weight: 400;
    font-size: 14px;
    color: #424242;
    word-break: break-all;

    svg {
      path {
        stroke: #0a0a0a;
      }
      margin-right: 6px;
    }
  }
  &__grid {
  }

  &__panel {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .smr-info + .smr-info {
    margin-top: 16px;
  }
}

.p-profile__paper-tabs-portfolio-bottom-checkbox {
  // transform: scale(2);
  input[type='checkbox'] {
    position: relative;
    appearance: none;
    width: 32px;
    height: 14px;
    background: rgba(#000000, 0.2);
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.4s;
  }

  input:checked[type='checkbox'] {
    background: rgba(#7da6ff, 0.5);
  }

  input[type='checkbox']::after {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    top: -20%;
    // transform: translateY(50%);
    left: 0;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
    transition: 0.4s;
  }

  input:checked[type='checkbox']::after {
    left: 50%;
    background: #3a57e8;
  }
}
