@import '../../assets/styles/variables';

.banner-welcome {
  background-color: $colorBlue;
  margin-top: -16px;
  box-sizing: border-box;
  padding: 60px 0 32px;
  margin-bottom: 32px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background-image: url('./bg.jpg');
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 0 72%;
    background-size: cover;
    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(#000, 0.5);
  }

  &__container {
    position: relative;
    z-index: 3;
  }

  &__title {
    font-family: $fontFamily;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.5px;
    color: $colorWhite;
    margin: 0 0 12px 0;
  }

  &__excerpt {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: $colorWhite;
    margin-bottom: 64px;
  }

  &__panels {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
    overflow-y: auto;

    @media (max-width: $breakpoint-tablet) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__panel {
    background-color: $colorWhite;
    border-radius: 10px;
    display: flex;
    box-sizing: border-box;
    padding: 8px;
    align-items: center;

    button {
      margin-top: auto;
      width: 100%;
    }

    > div {
      &:first-child {
        flex-shrink: 0;

        > img {
          width: 100px;
        }
      }

      &:last-child {
        flex-grow: 1;
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        height: 100%;

        > p {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.1px;
          color: #111a27;
          margin: 0;

          &:nth-child(2) {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: #111a27;
            margin-top: 6px;
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 640px) {
    display: none;
  }
}
