@import '../../assets/styles/variables';

.filePortfolio {
  // &__grid {
  //   display: flex;
  //   margin-left: -16px;
  //   margin-top: 16px;
  //   margin-bottom: 16px;

  //   > * {
  //     width: 50%;
  //     margin-left: 16px;
  //   }
  // }
  // display: flex;

  // flex-direction: column;
}

.flexBtnContainer {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
}

.fileInputs {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  div {
    width: 100%;
    input {
      max-width: 100%;
    }
  }
}

.cancelButton {
  display: flex;
  justify-content: center;
}

.submitBtn {
  // width: 1000px;
  display: flex;
  justify-content: flex-end;
  // justify-self: flex-end;
}
