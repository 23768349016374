@import 'normalize.css/normalize.css';
@import 'fonts';
@import 'variables';
@import 'margin_padding_helper';

html,
body {
  height: 100%;
}

body {
  font-family: $fontFamily;
  background-color: $colorGrayLight;
}

#root {
  height: 100%;
}

.b-wrapper {
  height: 100%;
}
