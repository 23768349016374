@import '../../assets/styles/variables';

.smr-footer {
  padding: 32px 0 60px;
  margin-top: 32px;
  text-align: center;
  background-color: #fff;

  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    text-align: left;
    border-bottom: 1px solid #eee;
    padding-bottom: 24px;
  }

  &__links {
    display: flex;
    flex-direction: column;

    p {
      color: #000 !important;
      font-weight: 500;
      margin: 0 0 4px;
    }
  }

  &__link {
    color: $colorGray;

    &:hover {
      text-decoration: underline;
    }

    span {
      font-size: 14px !important;
    }
  }

  &__socials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0 24px -16px;

    > * {
      color: $colorText;
      margin-left: 16px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: $colorGray;
    margin: 0;
  }
}
