.vehicles-filter {
  .tenders-filter {
    margin: 0 0 16px 0;
    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 16px;
    }

    &__footer {
      text-align: right;
      justify-content: flex-end;
    }
  }
}
