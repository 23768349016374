@import '../../assets/styles/variables';

.smr-expandable-form {
  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      display: flex;
      align-items: self-start;
    }
  }

  &__title {
    flex: 1 1 100%;
    margin: 0 1.5rem 0 0;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    color: $colorText;
    font-family: $fontFamily;

    @media (max-width: $breakpoint-tablet) {
      flex: 1;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.1px;
    margin: 0;
    color: $colorGray;

    &--not {
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 0;
      color: $colorText;
      font-family: $fontFamily;
    }
  }

  &__switch {
    margin-left: auto;
    flex-shrink: 0;
    flex-grow: 0;

    @media (max-width: $breakpoint-tablet) {
      margin-top: 0.5rem;
      margin-right: auto;
      margin-left: initial;
    }
  }

  &__content {
    padding: 1rem 0 0;
    box-sizing: border-box;
  }

  &__content_null_padding {
    padding: 0;
    box-sizing: border-box;
  }

  &__footer {
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
  }
}
