.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .head {
    background: #ffff;
    height: 84px;
    padding: 8px 16px;
    border-radius: 10px;
    font-weight: 900;
    font-size: 24px;
    display: flex;
    align-items: center;
  }

  .main {
    margin-top: 4px;
    .notificationLists {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  .footer {
    background: #ffff;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 16px;
    border-radius: 10px;
  }
}
