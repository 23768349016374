.container {
  width: 100%;

  .header {
    height: 64px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px;
    .back {
      width: 48px;
      height: 48px;
      background-color: #ededed;
      border-radius: 100%;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      svg {
        width: 14px;
        height: 14px;
        path {
          stroke: #0a0a0a;
        }
      }
    }
    .headerTitle {
      font-weight: 500;
      font-size: 32px;
      color: #0a0a0a;
      margin-right: 16px;
    }

    .date {
      font-weight: 400;
      font-size: 20px;
      color: #757575;
    }
  }
  .main {
    margin-top: 12px;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;

    .imgContainer {
      width: 721px;
      height: 371px;
      img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .context {
      margin-top: 16px;
      .description {
        font-weight: 400;
        font-size: 16px;
        color: #757575;
        line-height: 24px;
        letter-spacing: 0.1px;
      }
      .text {
        font-weight: 400;
        font-size: 20px;
        color: #0a0a0a;
        line-height: 28px;
        padding: 0;
        margin: 0;
        margin-top: 10px;
      }

      .info {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
        .comments,
        .shares,
        .likes {
          &:not(:last-child) {
            margin-right: 4px;
          }
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          border: 1px solid #e0e0e0;
          padding: 8px 8px;
          color: #616161;
          font-size: 16px;
          font-weight: 500;
          svg {
            margin-right: 4px;
          }
        }
      }
    }

    .comments {
      .input {
        margin-top: 16px;
        .label {
          font-weight: 400;

          font-size: 14px;
          color: #757575;
          text-transform: uppercase;
        }
        .inputContainer {
          background-color: #f5f5f5;
          border-radius: 10px;
          height: 56px;
          display: flex;
          align-items: center;
          padding: 12px;
          margin-top: 8px;
          .textInput {
            width: 100%;
            background: transparent;
            border: none;
            outline: none;

            &:placeholder {
              color: #0a0a0a;
            }
          }
          svg {
            cursor: pointer;
            path {
              stroke: #033159;
            }
          }
        }
      }

      .commentContainer {
        padding: 16px;
        background-color: #f5f5f5;
        border-radius: 10px;
        margin-top: 16px;

        .commentContext {
          .comment {
            font-weight: 400;
            font-size: 14px;
            color: #787878;
          }
          .text {
            font-weight: 400;
            font-size: 16px;
            color: #000;
            margin: 0;
            padding: 0;
            margin-top: 4px;
          }
        }

        .userInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .userContainer {
            display: flex;
            margin-top: 16px;
            padding-left: 16px;
            .userImg {
              width: 36px;
              height: 36px;
              border-radius: 100%;
              object-fit: cover;
              margin-right: 16px;
            }

            .userInfoContainer {
              list-style: none;
              padding: 0;
              margin: 0;
              .userName {
                font-weight: 400;
                font-size: 14px;
                color: #787878;
              }
              .firstName {
                font-weight: 400;
                font-size: 16px;
                color: #000;
              }
            }
          }
        }

        .buttons {
          .commentBtn,
          .likeBtn {
            background-color: transparent;
            border: none;
            cursor: pointer;
            svg {
              path {
                stroke: #033159;
              }
              width: 30px;
              height: 30px;
            }
          }

          .likeBtn {
            svg {
              path {
                stroke: #757575;
              }
            }
          }
        }
      }
    }
  }
}
