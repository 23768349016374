@import '../../../../assets/styles/variables';
.smr-card {
  &__timer-grid {
    min-height: 28px;
    border-radius: 4px;
    color: #ffff;
    font-weight: 500;
    font-size: 12px;
    padding: 2px 8px 2px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    svg {
      margin-right: 4px;
    }
  }
  .forGrid {
    position: absolute;
    margin-left: 8px;
    margin-top: 8px;
  }
  .free {
    background-color: $colorGreen;
  }

  .busy {
    background-color: $colorRed;
  }
}
