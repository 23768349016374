.castomCheckbox {
  input[type='checkbox'] {
    position: relative;
    appearance: none;
    width: 32px;
    height: 14px;
    background: rgba(#000000, 0.2);
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.4s;
  }

  input:checked[type='checkbox'] {
    background: rgba(#7da6ff, 0.5);
  }

  input[type='checkbox']::after {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    top: -20%;
    // transform: translateY(50%);
    left: 0;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
    transition: 0.4s;
  }

  input:checked[type='checkbox']::after {
    left: 50%;
    background: #3a57e8;
  }
}
