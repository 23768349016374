@import '../../assets/styles/variables';

.b-stepper {
  background-color: $colorGrayLight;

  .b-stepper {
    &__title {
      font-weight: 500;
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.5px;
      color: $colorText;
      font-family: $fontFamily;
      box-sizing: border-box;
      margin: 0 0 16px 0;
      background-color: $colorWhite;
      display: flex;
      justify-content: space-between;
      div {
        cursor: pointer;
        svg {
          width: 35px;
          height: 35px;
        }
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 2rem;
        line-height: 2.25rem;
        svg {
          width: 2.1875rem;
          height: 2.1875rem;
        }
      }
    }

    &__header {
      background-color: $colorWhite;
      padding: 16px 0;
      box-sizing: border-box;
    }

    &__steps {
      display: flex;
      align-items: center;
      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__item {
      display: flex;
      align-items: center;

      &--active {
        .b-stepper__number {
          background: $colorBlue;
          border-color: $colorBlue;
        }

        .b-stepper__label {
          font-weight: 500;
          color: $colorBlue;
        }
      }

      &--finished {
        .b-stepper__number {
          background: $colorCyan;
          border-color: $colorBlue;
          color: $colorBlue;
        }

        .b-stepper__label {
          color: $colorBlue;
        }
      }

      &:not(:first-child) {
        padding-left: 28px;
        box-sizing: border-box;
        margin-left: 10px;
        position: relative;
        display: inline-flex;
        align-items: center;

        &::before {
          content: '';
          height: 1px;
          width: 18px;
          position: absolute;
          left: 0;
          top: calc(50% - 0.5px);
          display: block;
          background-color: #e0e0e0;
        }
      }
    }

    @media (max-width: $breakpoint-tablet) {
      &__item:not(:first-child) {
        padding-left: 0;
        margin-left: 0;
        margin-top: 0.625rem;
        &::before {
          display: none;
        }
      }
    }

    &__number {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #c2c2c2;
      border: 1px solid #c2c2c2;
      color: $colorWhite;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      font-family: $fontFamily;
      @media (max-width: $breakpoint-tablet) {
        width: 2rem;
        height: 2rem;
      }
    }

    &__label {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 0 8px;
      color: #c2c2c2;
      font-family: $fontFamily;
      @media (max-width: $breakpoint-tablet) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    &__content {
      box-sizing: border-box;
      border-radius: 8px;
      padding: 16px 0;
    }
  }
}
