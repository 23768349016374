.container {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: flex-end;
  .count {
    display: flex;
    align-items: center;

    .countRow {
      color: #757575;
      font-weight: 400;
      font-size: 16px;
    }

    .rowCount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px 8px 12px;
      border-radius: 4px;
      background: #ffffff;
      border: 1px solid #ededed;
      cursor: pointer;
      margin-left: 9px;
      width: 90px;
      svg {
        // width: 55px;
        // height: 55px;
        path {
          stroke: #757575;
        }
      }
    }
  }

  .pagesCount {
    display: flex;
    align-items: center;
    margin-left: 24px;
    .pages {
      color: #757575;
      font-weight: 400;
      font-size: 16px;
    }

    .arrowLists {
      display: flex;
      align-items: center;
      margin-left: 24px;

      width: 74px;
      justify-content: space-between;
      .arrowLeft,
      .arrowRight {
        padding: 5px;
        border-radius: 10px;
        background: #ffffff;
        border: 1px solid #ededed;
        cursor: pointer;
        height: 34px;
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 55px;
          height: 55px;
          path {
            stroke: #757575;
          }
        }
      }

      .arrowLeft {
        svg {
          transform: rotate(90deg);
        }
      }
      .arrowRight {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
