.b-icon-button {
  background-color: #fff;
  border: none;
  outline: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: #eee;
  }

  &-notification {
    min-width: 12px;
    min-height: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ba1b1b;
    font-size: 7.5px;
    color: #ffff;
    border-radius: 100%;
    position: absolute;
    margin: 0;
    top: 9px;
    right: 8px;
  }
}
