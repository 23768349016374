@import '../../assets/styles/variables';

.b-portfolio-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  &__item {
    box-sizing: border-box;
    position: relative;
    // padding-left: 40px;
    margin: 0;
    // &::before {
    //   width: 15px;
    //   height: 15px;
    //   position: absolute;
    //   left: 0;
    //   top: 4px;
    //   border: 2px solid $colorBlue;
    //   background-color: #fff;
    //   content: '';
    //   border-radius: 50%;
    //   box-sizing: border-box;
    // }

    // &::after {
    //   width: 1px;
    //   height: calc(100% + 16px);
    //   position: absolute;
    //   left: 7px;
    //   top: 20px;
    //   content: '';
    //   background-color: #e9ecef;
    // }

    // &:last-child {
    //   &::after {
    //     display: none;
    //   }
    // }

    // + .b-portfolio-list__item {
    //   // margin-top: 32px;
    // }
  }

  &__name {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #04040b;
    margin: 0;
  }

  &__date,
  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 175%;
    color: #8a92a6;
    margin: 0;
  }
  &__attachment {
    display: flex;
    gap: 8px;
  }
  &__attachment-item {
    color: $colorBlue;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
  }
}
