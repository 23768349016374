.tenders-filter {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0 10px 10px 10px;

  &__grid {
    margin: 16px 0;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr;

    @media (min-width: 640px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}
