@import '../../assets/styles/variables';

.smr-nca-layer {
  &__text {
    margin: 0;
  }

  &__button {
    flex-direction: column;
    border-radius: 12px;
    height: 100px;
    border: none;
    outline: none;
    width: 100%;
    background-color: $colorGrayLight;
    color: $colorBlue;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    cursor: pointer;

    > span {
      margin-top: 8px;
    }
  }
}
