@import '../../assets/styles/variables';

.datePickerError {
  .helperText {
    color: $colorRed;
  }

  .wrapper {
    border: 1px solid $colorRed;
  }
}

.datePicker {
  // width: 100%;
  display: flex;
  flex-direction: column;

  .helperText {
    font-family: $fontFamily;
    font-size: 12px;
    margin: 8px 8px 0 8px;
  }

  .label {
    text-transform: uppercase;
    color: #757575;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .wrapper {
    display: flex;
    // flex-direction: row-reverse;
    // justify-content: flex-start;
    align-items: center;

    position: relative;
    margin-top: 8px;
    border-radius: 10px;
    background-color: #f5f5f5;
    height: 48px;
    padding: 12px;
    input {
      width: 100%;
      border: none;
      border-radius: 10px;
      background-color: #f5f5f5;
      outline: none;
      padding-right: 50px;
    }

    .icon {
      width: 24px;
      height: 24px;
      svg {
        width: 24px;
        height: 24px;
      }
      position: absolute;
      right: 12px;
      z-index: 111;
      top: 12px;
    }
  }
}
