@import '../../assets/styles/variables';

.content {
  background-color: #f3f4f6;
  padding: 0.5rem;
  border-radius: 5px;
}

.content-parent {
  height: 0px;
  overflow: hidden;
  transition: height ease 0.5 s;
}

.content-show {
  height: 200px;
}

.button {
  background-color: $colorWhite;
  border: none;
  width: 100%;
  text-align: left;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
  transition: 0.3s ease;
  & > svg > path {
    stroke: $colorBlue;
  }
}

.icon-rotate {
  transform: rotate(90deg);
}
