.b-accordion {
  margin-bottom: 20px;

  &__item {
    box-shadow: 0px 8px 32px rgba(0, 16, 41, 0.05);

    &--active {
      .b-accordion__arrow {
        transform: rotate(180deg);
      }

      .b-accordion__item-body {
        padding: 24px;
        opacity: 1;
        min-height: 0;
        height: auto;
        overflow: visible;
      }
    }

    &:last-child {
      .b-accordion__item-body {
        padding: 0;
      }
    }
  }

  &__item-status {
    margin-left: auto;
    margin-right: 40px;
  }

  &__item-header {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 8px 8px 0px 0px;
    padding: 14px 40px;
    cursor: pointer;

    &:hover {
      background-color: #f3f3f3;
    }
  }

  &__arrow {
    margin-right: 20px;
  }

  &__item-body {
    transition: opacity ease 0.2s;
    padding: 12px;
    height: 0;
    min-height: 0;
    overflow: hidden;
    background-color: #fff;
    opacity: 0;
  }
}
