@import '../../assets/styles/variables';

.b-switch {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 20px;

  &::after {
    content: '';
    display: block;
    background-color: #c2c2c2;
    border-radius: 7px;
    width: 32px;
    height: 14px;
    margin-top: 3px;
    transition: background-color 0.2s ease-in-out;
  }

  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    box-shadow: 0 0.1px 0.3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -10px;
    transition: left 0.2s ease-in-out;
  }

  &--checked {
    &::after {
      background-color: $colorCyan;
    }

    &::before {
      background-color: $colorBlue;
      left: calc(100% - 20px);
    }
  }

  &__input {
    display: none;
  }
}
