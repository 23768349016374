.container {
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    div {
      border: 5px solid white;
      border-bottom-color: #3a57e8;
    }
  }

  .sent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .sendText {
      margin-top: 12px;
      font-weight: 500;
      font-size: 24px;
      color: #000;
    }

    .sendButton {
      margin-top: 24px;
    }
  }

  .content {
    .text {
      font-weight: 400;
      font-size: 16px;
      color: #000;
      margin-top: 24px;
    }

    .input {
      margin-top: 16px;

      .buttonContainer {
        display: flex;
        justify-content: flex-end;

        .button {
          margin-top: 32px;
        }
      }
    }
  }
}
